import { useState } from 'react'

export const useCookiesStorage = (keyName) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = getCookie(keyName)

      if (value) {
        return JSON.parse(value)
      }

      return null
    } catch (err) {
      return null
    }
  })

  const setValue = (newValue, timeExpired) => {
    setCookie(keyName, JSON.stringify(newValue), timeExpired)
    setStoredValue(newValue)
  }

  const clearValue = (keyName) => {
    clearCookie(keyName)
    setStoredValue(null)
  }

  return [storedValue, setValue, clearValue]
}

function setCookie(name, value, minutes) {
  const date = new Date();
  date.setTime(date.getTime() + (minutes * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function getCookie(name) {
  const nameEQ = name + "=";
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    cookie = cookie.trim();
    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length);
    }
  }
  return null;
}

function clearCookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}
