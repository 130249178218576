import React, { createContext, useContext, useMemo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { apiService } from 'src/utils'
import { useCookiesStorage } from './useCookies'

const AuthContext = createContext()
const term = sessionStorage.getItem('term')

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  const [auth, setAuth, clearAuth] = useCookiesStorage('auth')
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const login = async (data) => {
    // login api
    try {
      setLoading(true)
      const res = await apiService.post('auth/login', {
        username: data.email,
        password: data.password,
      })
      setAuth(res.access_token, 360)
      navigate('/dashboard', { replace: true })
      setLoading(false)
    } catch (error) {
      setErr(error.response.data.message)
      setLoading(false)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logout = () => {
    // logout api
    clearAuth('auth')
    navigate('/login', { replace: true })
  }

  const value = useMemo(
    () => ({
      auth,
      login,
      logout,
      user,
    }),
    [auth, login, logout, user],
  )

  useEffect(() => {
    if (!auth) {
      return
    }
    const getMe = async () => {
      const data = await apiService.get('auth/profile')
      setUser(data)
      if (!term) {
        apiService.post('user/updateAccess', {
          userId: data.userId,
        })
      }
    }
    getMe()
  }, [auth])

  useEffect(() => {
    sessionStorage.setItem('term', 1)
  }, [])

  return (
    <AuthContext.Provider value={{ ...value, loading, err, setErr }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
