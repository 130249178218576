import axios from 'axios'
import queryString from 'query-string'
import {getCookie} from "../hooks/useCookies";

const baseURL = process.env.REACT_APP_BASE_URL

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    'content-type': 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
  responseType: 'json',
})

instance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error),
)

instance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error),
)

function requestHandler(request) {
  const token = JSON.parse(getCookie('auth'))
  request.headers.Authorization = `Bearer ${token}`
  return request
}

function responseHandler(response) {
  // if (response.data?.state == 3 || response.data?.state === 4) {
  //     store.dispatch({ type: LOG_OUT })
  // }
  if (response && response.data) {
    return response.data
  }
  return response
}

function errorHandler(error) {
  // if (error.response.status === 401 || error.response.status === 521) {
  //     store.dispatch({ type: LOG_OUT });
  // }
  return Promise.reject(error)
}

export default instance
